/**
|--------------------------------------------------
| Exporting api url to connect to.
|--------------------------------------------------
*/

export const baseUrl = "https://apin-oabu.onrender.com/api/v1"
// export const baseUrl = "http://localhost:5000/api/v1";
export const appUrl = "https://www.appealofinnovation.com/";
// export const appUrl = "http://localhost:3001";

