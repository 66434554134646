import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function Head() {
  const { pathname } = useLocation();
  return (
    <Helmet>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>
        {" "}
        {pathname === "/"
          ? "Dashboard - Home"
          : pathname === "/jobs"
          ? "Dashboard - Jobs": pathname === "/jobs/applications"
          ? "Dashboard - Applications for this job": pathname === "/jobs/new"
          ? "Dashboard - Add a new job"
          : pathname === "/quote-requests"
          ? "Dashboard - Quotes Requests"
          : pathname === "/newsletters"
          ? "Dashboard - Newsletters"
          : pathname === "/blog"
          ? "Dashboard - Blog"
          : "Dashboard - Welcome"}{" "}
      </title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}
